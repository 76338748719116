@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900;1000&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slider-wrapper {
  height: 220px;
  margin: 24px 0;
}

.slider-wrapper .slick-list {
  height: 158px;
}

.slider-wrapper .slick-dots {
  display: flex !important;
  bottom: -60px;
}

.slider-wrapper .slick-dots li {
  margin: 0;
}

.slider-wrapper .slick-dots li.slick-active button:before {
  color: #fda92f;
  opacity: 1;
}

.slider-wrapper .slick-dots li button:before {
  font-size: 12px;
}

.purple-checkbox:checked,
.purple-checkbox[checked="true"],
.purple-checkbox[aria-checked="true"] {
  background-color: #1f2937;
  background-image: linear-gradient(
      -45deg,
      transparent 65%,
      hsl(239, 65%, 60%) 65.99%
    ),
    linear-gradient(45deg, transparent 75%, hsl(239, 65%, 60%) 75.99%),
    linear-gradient(-45deg, hsl(239, 65%, 60%) 40%, transparent 40.99%),
    linear-gradient(
      45deg,
      hsl(239, 65%, 60%) 30%,
      hsl(0 0% 100%) 30.99%,
      hsl(0 0% 100%) 40%,
      transparent 40.99%
    ),
    linear-gradient(-45deg, hsl(0 0% 100%) 50%, hsl(239, 65%, 60%) 50.99%);
  background-repeat: no-repeat;
  animation: checkmark 0.2s ease-out;
}

.pulse-loading::after {
  content: ".";
  animation: dots 1s steps(5, end) infinite;
}

.slide-text {
  animation: slideText 10s infinite linear;
}

@keyframes checkmark {
  0% {
    background-position-y: 5px;
  }
  50% {
    background-position-y: -2px;
  }
  100% {
    background-position-y: 0;
  }
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: #6b7280;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 #6b7280, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 #6b7280, 0.5em 0 0 #4b5563;
  }
}

@keyframes slideText {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-101%);
  }
}